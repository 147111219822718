<template>
  <view-container name="registration">
    <view-content>
      <div data-vue-name="Registration" class="container-fluid auth">
        <div class="auth__title">Welcome</div>
        <form class="auth__form" @submit.prevent="handleSubmit">
          <AuthInput
            v-model="user.name"
            :placeholder="'Enter your Name'"
            :error="showNameError && !isValidName"
            :errorText="getNameErrorText"
            :maxLength="30"
          />

          <AuthInput
            v-model="user.email"
            :valid="isValidMail"
            :placeholder="'Enter your Email'"
            :error="showEmailError && !isValidMail"
            :errorText="getEmailErrorText"
          />

          <AuthInput
            v-model="user.password"
            :type="'password'"
            :placeholder="'Password (at least 6 characters)'"
            :error="hasPassError"
            :errorText="getPassErrorText"
          />

          <CommonButton class="submit-button" :type="'submit'" :caption="'Join Competa'" />

          <div>
            Already have an account?
            <router-link class="auth__link" to="/authorization">Sign in</router-link>
          </div>
        </form>

        <div class="auth__other-account">
          <CommonButton
            @clicked="handleGoogleSubmit"
            :caption="'Join with Google'"
            :variant="'social'"
            :is-disabled="disableSocial"
          >
            <svg class="ico-google">
              <use v-if="disableSocial" xlink:href="../assets/img/svg-sprite.svg#icoGoogleDisable"></use>
              <use v-else xlink:href="../assets/img/svg-sprite.svg#icoGoogle"></use>
            </svg>
          </CommonButton>
        </div>
      </div>
    </view-content>
  </view-container>
</template>

<script>
import { mapState } from 'vuex'
import eventBus from '@/plugins/eventBus'
import AuthInput from '@/components/UI/AuthInput'
import CommonButton from '@/components/UI/CommonButton'

export default {
  name: 'Registration',
  components: {
    AuthInput, CommonButton
  },
  computed: {
    ...mapState({
      errors: ({ auth }) => auth.errors,
      invitationHash: ({ eventHash }) => eventHash.hash
    }),
    hasPassError() {
      return (this.errors.password?.[0] || this.showPassError) && !this.isValidPass;
    },
    getNameErrorText() {
      return this.errors.name?.[0] || this.nameErrorMessageFront || '';
    },
    getEmailErrorText() {
      return this.errors.email?.[0] || this.emailErrorMessageFront || '';
    },
    getPassErrorText() {
      return this.errors.password?.[0] || this.passErrorMessageFront || '';
    },
    isValidPass() {
      return this.user.password.length >= 6;
    },
    isValidMail() {
      return this.validEmail(this.user.email)
    },
    isValidName() {
      return this.user.name.length > 0;
    },
    hash() {
      return this.$route.params.hash || null
    },
    disableSocial() {
      return false;
    }
  },
  data() {
    return {
      user: {
        email: '',
        name: '',
        password: '',
      },
      confirmPassword: '',
      passwordInputType: 'password',
      confirmPasswordInputType: 'password',
      confirmedPassportError: false,

      showPassError: false,
      showEmailError: false,
      showNameError: false,
      passErrorMessageFront: 'Password must contain at least 6 characters',
      emailErrorMessageFront: 'Incorrect email address',
      nameErrorMessageFront: 'You need to provide a name',
    }
  },
  methods: {
    handleSubmit() {
      this.showPassError = false;
      this.showEmailError = false;
      this.showNameError = false;

      if (!this.isValidMail || !this.isValidPass || !this.isValidName) {
        if (!this.isValidPass) {
          this.showPassError = true;
        }
        if (!this.isValidMail) {
          this.showEmailError = true;
        }
        if (!this.isValidName) {
          this.showNameError = true;
        }
        return;
      }

      if (this.hash) this.user.invite_hash = this.hash
      if (this.invitationHash) this.user.event_invite_hash = this.invitationHash

      this.$store.dispatch('register', this.user)
        .then(() => {
          this.$store.commit("eventHash/RESET_HASH")
          this.$router.push({ name: 'authorization' })
        })
        .then(() => eventBus.$emit('registered'))
    },

    handleGoogleSubmit() {
      this.$store.dispatch('loginWithGoogle').then(res => {
        console.log('res ->', res) // eslint-disable-line no-console
        window.location = res.redirect
      })
    },
    validEmail(email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(email)
    },
  },
}
</script>

<style lang="stylus">
.registration-view {
  .view-content {
    padding 0 !important
  }
}
</style>

<style scoped>
  .auth__form > div {
    color: #eee;
  }

  .submit-button {
    margin: 40px 0 16px;
  }
</style>
