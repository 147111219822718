<template>
  <button
    @click="clicked"
    :class="[{ disabled: isDisabled }, variant, color]"
    :disabled="isDisabled"
    :type="type"
    class="button"
  >
    <slot></slot>
    <Icon v-if="iconBefore" :type="iconBefore" :size="iconSize" class="button__icon" />
    {{ caption }}
    <Icon v-if="iconAfter" :type="iconAfter" :size="iconSize" class="button__icon" />
  </button>
</template>

<script>
import Icon from '@/components/Icon.vue';

export default {
  components: {
    Icon
  },
  props: {
    caption: {
      type: String,
      required: true
    },
    iconSize: {
      type: [Number, String],
      default: 24
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    iconBefore: {
      type: String,
      default: ''
    },
    iconAfter: {
      type: String,
      default: ''
    },
    variant: {
      type: String,
      default: 'flat',  // flat / outline / inline / text
    },
    color: {
      type: String, // primary / begin / start / info
      default: 'primary'
    },
    type: {
      type: String,
      default: 'button'
    },
    isPrevent: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    clicked(e) {
      if (this.type === 'button') {
        if (this.isPrevent) e.preventDefault();
        this.$emit('clicked');
      } else {
        this.$emit('submit');
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.button
  --inner-gap 8px
  --text-color #EEEEEE
  --button-height 48px
  display flex
  justify-content center
  align-items center
  gap var(--inner-gap)
  width 100%
  height var(--button-height)
  line-height: 20px;
  padding 0 12px
  background-color #262B3F
  color var(--text-color)
  border-radius 8px
  font-family Manrope, sans-serif
  font-size 16px
  font-weight 500
  border 1px solid transparent
  transition background-color 0.05s, border-color 0.05s

  &.flat
    &.primary
      background-color #D21341
      border-color #D21341

    &:active
      background-color #AE0C33

    &.disabled,
    &:disabled
      background-color: #1D202E;
      --text-color: #7B8B97;

  &.outline
    &.primary
      background-color transparent // #1D202E
      border-color #D21341

    &:active
      border-color #AE0C33

    &.disabled,
    &:disabled
      border-color #1D202E
      --text-color #7B8B97

  &.text
    --button-height 20px
    font-weight 400
    font-size 16px
    color #6293FF
    width max-content
    background-color transparent

    &:active
      color #EEEEEE

    &:disabled,
    &.disabled
      --text-color #7B8B97

  &.inline
    --inner-gap: 8px
    --button-height 20px
    padding: 0
    width max-content
    background-color transparent
    font-weight 600
    font-size 12px
    letter-spacing 0.05em
    text-transform uppercase

    &.primary
      --text-color: #D8D8D8;

    &.begin
      --text-color: #5BBE57;

    &.start
      --text-color: #EB1E59;

    &.info
      --text-color: #6293FF;

    &.disabled,
    &:disabled
      --text-color: #7B8B97

  &.social
    gap 8px
    background #D8D8D8
    --text-color #141723

    &:active
      background #B4B3B3

    &.disabled,
    &:disabled
      --text-color #7B8B97
      background-color: #1D202E

  .icon
    fill var(--text-color)
    color var(--text-color)

</style>
