<template>
  <div class="field" :class="{error: error}">
    <input
      @input="changeValue($event.target.value)"
      :type="inputType"
      :value="modelValue"
      :placeholder="placeholder"
      :maxlength="maxLength"
      :required="isRequired"
    />
    <i v-if="type === 'password'" @click="switchVisibility">
      <IconPassHide v-if="inputType === 'password'" />
      <IconPassShow v-else />
    </i>
    <i v-if="valid"><IconChecked /></i>
    <span v-if="error" class="field__error-message">{{ errorText }}</span>
  </div>
</template>

<script>
import IconPassShow from '@/components/Icons/IconPassShow';
import IconPassHide from '@/components/Icons/IconPassHide';
import IconChecked from '@/components/Icons/IconChecked';

export default {
  components: {
    IconPassHide, IconPassShow, IconChecked
  },
  model: {
    prop: 'modelValue',
    event: 'input'
  },
  props: {
    type: {
      type: String,
      default: 'text'
    },
    placeholder: {
      type: String,
      default: ''
    },
    error: {
      type: Boolean,
      default: false
    },
    errorText: {
      type: String,
      default: ''
    },
    valid: {
      type: Boolean,
      default: false
    },
    modelValue: {
      type: String,
      default: ''
    },
    maxLength: {
      type: Number,
      default: 50
    },
    isRequired: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      inputType: this.type
    }
  },
  methods: {
    changeValue(value) {
      this.$emit('input', value);
    },
    switchVisibility() {
      this.inputType = this.inputType === 'password' ? 'text' : 'password'
    },
  },
}
</script>

<style lang="stylus" scoped>
  .field {
    position: relative;
    margin: 0 0 16px;

    &.error input {
      border-color: #EA3E3E;
    }

    i {
      position: absolute;
      z-index: 10;
      cursor: pointer;
      width: 24px;
      height: 24px;
      right: 16px;
      top: 12px;
    }

    input {
      width: 100%;
      background: rgba(82, 85, 93, 0.4);
      border-radius: 8px;
      font-size: 16px;
      font-weight: 400;
      line-height: 1.25;
      color: #D8D8D8;
      padding: 13px 46px 13px 16px;
      border: 1px solid transparent;
      margin: 0

      &::placeholder {
        color: #7B8B97;
      }
    }

    input:-internal-autofill-selected,
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus {
      -webkit-text-fill-color: #D8D8D8;
      -webkit-box-shadow: 0 0 0 40rem #41444d inset;
      border: 0;
      padding: 14px 46px 14px 16px;
    }

    &__error-message {
      display: block;
      color: #EA3E3E;
      margin: 4px 16px 0;
      font-size: 12px;
      line-height: 1.33;
    }
  }
</style>
